const Data = {
    homeItems: [
      {
        name: "Home",
        path: "/"
      },
      {
        name: "App Screen",
        path: "/#appScreen",
        offset: "-50"
      },
      {
        name: "App",
        path: "/#firstApp",
        offset: "-50"
      },
      // {
      //   name: "Blog",
      //   path: "/blog-grid",
      //   subItems: [
      //     {
      //       name: "Blog Grid",
      //       path: "/blog-grid"
      //     },
      //     {
      //       name: "Blog List",
      //       path: "/blog-list"
      //     },
      //     {
      //       name: "Blog Full",
      //       path: "/blog-full"
      //     },
      //     {
      //       name: "Blog Single",
      //       path: "/blog-single"
      //     }
      //   ]
      // },
      {
        name: "Contact",
        path: "/#bottomPart",
        offset: "-50"
      }
    ],
    todoPageItems:[
      {
        name: "Home",
        path: "/"
      },
      {
        name: "Features",
        path: "/todoAnything#appFeatures",
        offset: "-50"
      },
      {
        name: "Contact",
        path: "/todoAnything#bottomPart",
        offset: "-50"
      }
    ],
    pomoPageItems:[
      {
        name: "Home",
        path: "/"
      },
      {
        name: "Features",
        path: "/miniPomo#appFeatures",
        offset: "-50"
      },
      {
        name: "Contact",
        path: "/miniPomo#bottomPart",
        offset: "-50"
      }
    ],
    waterPageItems:[
      {
        name: "Home",
        path: "/"
      },
      {
        name: "Features",
        path: "/drinkReminder#appFeatures",
        offset: "-50"
      },
      {
        name: "Contact",
        path: "/drinkReminder#bottomPart",
        offset: "-50"
      }
    ],
    defaultItems:[
      {
        name: "Home",
        path: "/"
      },
      {
        name: "Contact",
        path: "/drinkReminder#bottomPart",
        offset: "-50"
      }
    ],
    errorItems:[
      {
        name: "Home",
        path: "/"
      }
    ]
  };
  export default Data;
  